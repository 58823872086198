<template>
  <div id="UserDetail">
    <v-breadcrumbs :items="breadItems" divider=">"></v-breadcrumbs>
    <v-alert
      v-if="this.alertCompte"
      class="ma-6"
      color="blue-grey"
      icon=" mdi-wrench"
    >
      Compte en cours de création veuillez patienter
    </v-alert>
    <form style="margin-top: 40px">
      <v-col>
        <v-row>
          <v-avatar v-if="Images === null" style="margin-left: 30px" size="128">
            <img :src="avatar" />
          </v-avatar>
          <v-avatar v-else style="margin-left: 30px" size="128">
            <img :src="Images" />
          </v-avatar>

          <v-col md="3">
            <v-text-field
              style="margin-top: 30px"
              label="Nom d'utilisateur"
              placeholder="Nom d'utilisateur"
              v-model="username"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              style="margin-top: 30px"
              label="ID d'utilisateur "
              v-model="userId"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              label="Email "
              style="margin-top: 30px"
              v-model="email"
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col v-if="TypeDeCompte === true">
            <v-chip style="margin-top: 40px" color="green" text-color="white">
              Compte Professionnel
            </v-chip>
          </v-col>
          <v-col v-if="TypeDeCompte === false">
            <v-chip style="margin-top: 40px" color="blue" text-color="white">
              Compte Particulier
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex">
        <v-col class="pa-3 d-flex flex-column" style="margin-top: 12px">
          <v-card>
            <v-card-title
              >Roles :&nbsp;
              <v-chip v-if="userRole.admin" color="blue" text-color="white">
                Admin </v-chip
              >&nbsp;
              <v-chip v-if="userRole.editor" color="green" text-color="white">
                Editeur </v-chip
              >&nbsp;
              <v-chip v-if="userRole.guest" color="red" text-color="white">
                Externe
              </v-chip>
              <v-chip
                v-if="userRole.mercarueCustomerPro"
                color="orange"
                text-color="white"
              >
                Professionnel
              </v-chip>
            </v-card-title>
            <v-row>
              <v-col cols="6" md="6" style="margin-left: 12px">
                <v-select
                  :items="items"
                  clearable
                  hide-details
                  hide-selected
                  placeholder="ChangeRole"
                  label="Changer Role"
                  v-model="NewRole"
                ></v-select>
              </v-col>
              <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-5"
                      color="#19b4bf"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="NewRole == null"
                    >
                      Confirmer
                      <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Changement du Role
                    </v-card-title>

                    <v-card-text> Êtes vous sur de votre choix ? </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red lighten-2" text @click="dialog = false">
                        Non
                      </v-btn>
                      <v-btn
                        color="#19b4bf"
                        text
                        v-on:click.prevent="updateRole"
                        @click="dialog = false"
                      >
                        Oui
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-row>
            <v-card-text>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6" class="pa-3 d-flex flex-column" style="margin-top: 12px">
          <v-card>
            <v-card-title>Details</v-card-title>
            <v-card-text>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
              <v-col class="d-flex">
                <v-menu
                  v-model="menuCalendar"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  outlined
                  readonly
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Date d'inscription"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </v-col>
              <v-col class="d-flex" style="margin-top: 15px">
                <v-text-field
                  label="Nom"
                  v-model="name"
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  class="mx-8"
                  label="Prénom"
                  v-model="surname"
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  label="Gender "
                  v-model="gender"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col class="d-flex justify-center" style="margin-left: 15px">
                <v-text-field
                  style="max-width: 100px"
                  class="mx-2"
                  label="Pays"
                  v-model="pays"
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  style="max-width: 300px"
                  class="mx-2"
                  label="Numéro de téléphone"
                  v-model="NumTel"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex">
          <v-col>
            <v-card>
              <v-card-title>Adresse</v-card-title>
              <v-card-text>
                <v-divider></v-divider>
                <v-col v-for="addresses in addresses" v-bind:key="addresses.id">
                  <v-col class="d-flex">
                    <v-text-field
                      label="Adresse"
                      outlined
                      v-model="addresses.addressName"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex">
                    <v-text-field
                      label="Rue"
                      v-model="addresses.addressName"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    class="d-flex justify-center"
                    style="margin-left: 15px"
                  >
                    <v-text-field
                      style="max-width: 200px"
                      class="mx-2"
                      label="Ville"
                      v-model="addresses.locality"
                      outlined
                      readonly
                    ></v-text-field>
                    <v-text-field
                      style="max-width: 200px"
                      class="mx-2"
                      label="Code Postal"
                      v-model="addresses.postalCode"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-col>
    </form>
    <v-row>
      <v-col>
        <v-col>
          <v-card>
            <v-card-title> Produits </v-card-title>

            <v-card-text>
              <v-col>
                <v-row>
                  <v-col cols="6" md="3">
                    <v-autocomplete
                      :items="productMarks"
                      item-value="markId"
                      item-text="mark"
                      label="Marques de produits"
                      chips
                      clearable
                      hide-details
                      hide-selected
                      v-model="mark"
                      @input="onProductMarksChange"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-autocomplete
                      :items="productTypes"
                      item-text="type"
                      item-value="typeId"
                      label="Sous-catégorie du produits"
                      chips
                      clearable
                      hide-details
                      hide-selected
                      v-model="type"
                      @input="onProductTypesChange"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" md="3">
                    <v-autocomplete
                      :items="productStatus"
                      chips
                      clearable
                      hide-details
                      hide-selected
                      label="Statut de produit"
                      v-model="statut"
                      item-value="statut"
                      @input="onProductStateChange"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2" md="3" style="margin-top: 10px">
                    <hotel-date-picker
                      style="opacity: 90%; z-index: 2"
                      :halfDay="true"
                      :displayClearButton="true"
                      :i18n="frFR"
                      :positionRight="true"
                      :minNights="1"
                      :format="dateFormat"
                      :showYear="true"
                      :showCloseButton="false"
                      :startDate="new Date(new Date().getFullYear(), 0, 1)"
                      :endDate="new Date(Date.now() + 3600 * 1000 * 24)"
                      @check-in-changed="checkInChanged($event)"
                      @check-out-changed="checkOutChanged($event)"
                      @clear-selection="clear($event)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-card>
                <v-data-table
                  :footer-props="{
                    'items-per-page-options': [5, 10, 15, 30],
                  }"
                  :headers="headers"
                  :items="products"
                  :search="search"
                  :server-items-length="totalProductsCount"
                  :options.sync="options"
                  :loading="loading"
                  class="elevation-1"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td>
                        <v-btn @click="redirectToEditProduct(row.item)" text>
                          <span class="mr-2">{{ row.item.product_id }}</span>
                        </v-btn>
                      </td>
                      <td>{{ row.item.mark }}</td>
                      <td>{{ row.item.type }}</td>
                      <td>{{ row.item.name }}</td>
                      <td>
                        <v-row>
                          <v-col v-if="row.item.state === 'POSTE'">
                            <v-tooltip left max-width="250">
                              <template #activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  class="ma-2"
                                  color="blue"
                                  text-color="white"
                                >
                                  Posté
                                </v-chip>
                              </template>
                              <span
                                >Le produit est posté et en attente de
                                validation par nos administrateurs.
                              </span>
                            </v-tooltip>
                          </v-col>
                          <v-col v-if="row.item.state === 'INVALIDE'">
                            <v-tooltip left max-width="250">
                              <template #activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  class="ma-2"
                                  color="red"
                                  text-color="white"
                                >
                                  Invalide
                                </v-chip>
                              </template>
                              <span
                                >Le produit a été invalidé par nos
                                administrateur car il n'est pas conforme par
                                rapports aux saisies faites.
                              </span>
                            </v-tooltip>
                          </v-col>
                          <v-col v-if="row.item.state === 'PUBLIE'">
                            <v-tooltip left max-width="250">
                              <template #activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  class="ma-2"
                                  color="green"
                                  text-color="white"
                                >
                                  Publié
                                </v-chip>
                              </template>
                              <span
                                >Le produit a été validé et publié par nos
                                administrateurs, il est visible publiquement et
                                à tous les utilisateurs sur la plateforme
                                Mercarue.
                              </span>
                            </v-tooltip>
                          </v-col>
                          <v-col v-if="row.item.state === 'VALIDE'">
                            <v-tooltip left max-width="250">
                              <template #activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  class="ma-2"
                                  color="amber"
                                  text-color="white"
                                >
                                  Validé
                                </v-chip>
                              </template>
                              <span
                                >Le produit a été validé par nos administrateurs
                                et en attente de publication.
                              </span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </td>
                      <td>{{ row.item.date }}</td>
                      <td>{{ row.item.userName }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import "vue-json-pretty/lib/styles.css";
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import Constants from "./../../utils/constants";
import FirebaseDB from "../../utils/firebaseDB";
import nodesFieldName from "../../utils/nodesFieldName";
import moment from "moment";
import FunctionsUtils from "./../../utils/functions";
import HotelDatePicker from "vue-hotel-datepicker2";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
import INVALIDE from "@/assets/iconeStatut/INVALIDE.svg";
import POSTE from "@/assets/iconeStatut/POSTE.svg";
import PUBLIÉ from "@/assets/iconeStatut/PUBLIÉ.svg";
import VALIDE from "@/assets/iconeStatut/VALIDE.svg";
import avatar from "@/assets/avatar.jpg";
import firebase from "firebase";

export default {
  name: "UserDetail",
  components: {
    HotelDatePicker,
  },
  data() {
    return {
      breadItems: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Liste des utilisateurs",
          disabled: false,
          to: "/MercaAdmin/Users",
        },
      ],
      items: ["Admin", "Editeur", "Professionnel", "Externe"],
      NewRole: null,
      addresses: null,
      userRole: [],
      avatar: avatar,
      NumTel: null,
      pays: null,
      surname: null,
      Images: null,
      gender: null,
      TypeDeCompte: null,
      email: null,
      username: null,
      userId: null,
      name: null,
      date: null,

      menuCalendar: null,
      //product data
      INVALIDE: INVALIDE,
      POSTE: POSTE,
      PUBLIÉ: PUBLIÉ,
      VALIDE: VALIDE,
      frFR: {
        night: "jour",
        nights: "jours",
        "check-in": "",
        "check-out": "Date de Creation",
        "day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        tooltip: {
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
        },
      },
      fromDate: new Date("2021-01-01"),
      toDate: new Date(Date.now() + 3600 * 1000 * 24),

      productStatus: [
        Constants.POSTE,
        Constants.VALIDE,
        Constants.INVALIDE,
        Constants.PUBLIE,
      ],
      dates: [],
      menu: false,
      modalTo: false,
      modalFrom: false,
      products: [],
      totalProducts: [],
      search: "",
      productMarks: [],
      options: {},
      productTypes: [],
      from: null,
      to: null,
      productTypeForAddProduct: null,
      type: null,
      mark: null,
      state: null,
      statut: null,
      totalProductsCount: 0,
      loading: true,
      lastVisibleProduct: null,
      dates: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      //Role Data
      uid: null,
      userId: null,
      dialog: null,
      index_at: null,
      domain: null,
      alertCompte: false,
    };
  },

  async beforeRouteEnter(to, from, next) {
    db.collection(NodesName.USERS_DETAILS)
      .doc(to.params.userId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          next((vm) => {
            vm.userId = doc.id;
            vm.username = doc.data().userName;
            vm.name = doc.data().firstName;
            vm.surname = doc.data().lastName;
            vm.email = doc.data().email;
            vm.TypeDeCompte = doc.data().professional;
            vm.date = moment(
              new Date(doc.data().creationDate.seconds * 1000)
            ).format("YYYY-MM-DD");
            vm.Images = doc.data().photoUrl;
            vm.gender = doc.data().gender;
            vm.pays = doc.data().phoneCountryNameCode;
            vm.NumTel = doc.data().phoneNumber;
            vm.addresses = doc.data().addresses;
          });
        } else {
          console.log("No such document!");
        }
      });
  },
  methods: {
    //Fonction Update Role
    updateRole() {
      this.uid = this.userId;
      var index_at = this.email.indexOf("@");
      this.domain = this.email.substring(index_at + 1);

      // Role Professionnel
      if (this.NewRole == "Professionnel") {
        {
          var addMessage = firebase.functions().httpsCallable("set_user_role");

          var data = {
            uid: this.uid,
            role: {
              guest: false,
              admin: false,
              editor: false,
              mercarueCustomerPro: true,
            },
          };

          addMessage(data)
            .then(function (result) {})
            .catch(function (error) {
              console.log(error);
            });
        }
      } else if (this.NewRole == "Externe") {
        var addMessage = firebase.functions().httpsCallable("set_user_role");

        var data = {
          uid: this.uid,
          role: { guest: true, admin: false, editor: false },
        };

        addMessage(data)
          .then(function (result) {})
          .catch(function (error) {
            console.log(error);
          });
      } else if (this.domain == "mercarue.com") {
        if (this.NewRole == "Admin") {
          var addMessage = firebase.functions().httpsCallable("set_user_role");

          var data = {
            uid: this.uid,
            role: { guest: false, admin: true, editor: true }, // Add pro
          };

          addMessage(data)
            .then(function (result) {})
            .catch(function (error) {
              console.log(error);
            });
        } else if (this.NewRole == "Editeur") {
          var addMessage = firebase.functions().httpsCallable("set_user_role");

          var data = {
            uid: this.uid,
            role: { guest: false, admin: false, editor: true },
          };

          addMessage(data)
            .then(function (result) {})
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        alert(
          //Msg a modifier
          "compte non Mercarue vous ne vous pas modifier le role de ce compte "
        );
      }
      this.$router.push({
        name: "Users",
      });
    },
    clear() {
      this.fromDate = new Date("2021-01-01");
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
    },
    checkInChanged(newDate) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.fromDate = newDate;
      this.loadProducts(this.mark, this.type, this.statut);
    },
    checkOutChanged(newDate) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.toDate = newDate;
      this.loadProducts(this.mark, this.type, this.statut);
    },
    onProductTypesChange: function (event) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.loadProducts(this.mark, event, this.statut);
    },
    onProductMarksChange: function (event) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.loadProducts(event, this.type, this.statut);
    },
    onProductStateChange: function (event) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.loadProducts(this.mark, this.type, event);
    },
    redirectToEditProduct(item) {
      this.$router.push({
        name: "ProductDetail",
        params: {
          product_id: item.product_id,
          mark: item.mark,
          type: item.type,
          userId: item.userId,
          statut: item.statut,
        },
      });
    },
    async loadProducts(markSelected, typeSelected, stautSelected) {
      this.loading = true;
      localStorage.setItem("markForPostedProduct", markSelected);
      localStorage.setItem("typeForPostedProduct", typeSelected);
      localStorage.setItem("statutForPostedProduct", stautSelected);
      this.mark = localStorage.getItem("markForPostedProduct");
      this.type = localStorage.getItem("typeForPostedProduct");
      this.statut = localStorage.getItem("statutForPostedProduct");
      if (this.mark || this.type || this.statut) {
        if (!this.fromDate && !this.toDate) {
          this.fromDate = new Date("2021-01-01");
          this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
        }
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        var query = db
          .collection(NodesName.PRODUCT)
          .where(nodesFieldName.CREATION_DATE, ">=", this.fromDate)
          .where(nodesFieldName.CREATED_BY, "==", this.userId)
          .where(nodesFieldName.CREATION_DATE, "<=", this.toDate);
        if (
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
          FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
          FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
        ) {
          query = query.where(nodesFieldName.MARK, "==", this.mark);
        } else if (
          FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
          FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
        ) {
          query = query.where(nodesFieldName.SUBCATEGORY, "==", this.type);
        } else if (
          FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
          FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
        ) {
          query = query.where(nodesFieldName.STATE, "==", this.statut);
        } else if (
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
          FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
        ) {
          query = query
            .where(nodesFieldName.MARK, "==", this.mark)
            .where(nodesFieldName.SUBCATEGORY, "==", this.type);
        } else if (
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
          FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
        ) {
          query = query
            .where(nodesFieldName.MARK, "==", this.mark)
            .where(nodesFieldName.STATE, "==", this.statut);
        } else if (
          FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
          !FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
        ) {
          query = query
            .where(nodesFieldName.SUBCATEGORY, "==", this.type)
            .where(nodesFieldName.STATE, "==", this.statut);
        } else {
          query = query.where(nodesFieldName.CREATED_BY, "==", this.userId);
        }

        var generalStats = await FirebaseDB.getGeneralStats();
        generalStats.forEach((stat) => {
          this.totalProductsCount = stat.data().number_total_products;
        });

        if (this.totalProducts.length < this.totalProductsCount) {
          var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
            query,
            this.lastVisibleProduct,
            itemsPerPage,
            this.totalProductsCount
          );
          let i = 0;
          querySnapshot.forEach((product) => {
            if (Object.entries(product.data()).length !== 0) {
              const found = this.totalProducts.some(
                (el) => el.product_id === product.id
              );

              if (!found) {
                this.totalProducts.push({
                  mark: product.data().mark,
                  type: product.data().subCategory,
                  product_id: product.data().uniqueId,
                  name: product.data().name,
                  state: product.data().state,

                  userId: product.data().createdBy,
                  date: moment(
                    new Date(product.data().creationDate.seconds * 1000)
                  ).format("L HH:mm:ss"),
                });

                i++;
                if (querySnapshot.size == i) {
                  this.lastVisibleProduct = product;
                }
              }
            }
          });
        }

        this.products = FunctionsUtils.customSortDataTable(
          this.totalProducts,
          sortBy,
          sortDesc
        );
        this.products = FunctionsUtils.getItemsPerPageForDataTable(
          this.totalProducts,
          itemsPerPage,
          page,
          this.totalProductsCount
        );
      }
      this.loading = false;
    },
  },
  created() {
    // Récupérer les marques de produit
    db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.productMarks.push({
            mark: doc.data().name,
            markId: doc.data().uniqueId,
          });
        });
      });
    // Récupérer les types de produit
    db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.productTypes.push({
            type: doc.data().name,
            typeId: doc.data().uniqueId,
          });
        });
      });
  },
  computed: {
    dateFormat() {
      return "MM/DD/YYYY";
    },
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    },
    headers() {
      return [
        { text: "ID", value: "product_id" },
        { text: "Mark", value: "mark" },
        { text: "Sous-catégorie", value: "type" },
        { text: "Name", value: "name" },
        { text: "Status", value: "state" },
        { text: "Date de Creation", value: "date" },
      ];
    },
  },
  mounted() {
    db.collection(NodesName.USERS_DETAILS)
      .doc(this.userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().role) {
            this.userRole = doc.data().role;
          } else {
            this.alertCompte = true;
          }
        }
      });
  },
  watch: {
    options: {
      handler() {
        this.loadProducts(this.mark, this.type, this.statut);
      },
      deep: true,
    },
  },
};
</script>
